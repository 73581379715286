<template>
    <div>
        <iframe :src="url" id="myiframe" width="100%" height="100%" style="border: none;min-height:1038px"></iframe>
    </div>
</template>
<script>
export default {
    data() {
        return {
            url: "",
            userInfo: {},
        };
    },
    created() {
        if (localStorage.getItem("unserInfoData")) {
            this.userInfo = JSON.parse(localStorage.getItem("unserInfoData"));
        }
        if (this.$route.name == "publicPages1") {
            this.url = this.$route.query.url + "/home/#/resourcePool/resourceHome?hlw=1";
            return;
        }
        this.url = this.$route.query.url;
        console.log(this.url);
    },
    mounted() {
        let myiframe = document.getElementById('myiframe');
        window.addEventListener('message', function (event) {
            console.log(event.data);
            if (location.origin != event.origin) {
                if (event.data.type == 'hashH') {
                    myiframe.height = event.data.data + 'px'
                }
            }
        })
    },
    methods: {},
};
</script>